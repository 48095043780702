/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import GravityForm from 'components/GravityForm'
import Hero from 'components/elements/Hero'
import ParseContent from 'components/shared/ParseContent'
import ContactDetails from 'components/shared/ContactDetails'
import KoelMonteurs from 'components/shared/before-footer/Koelmonteurs'

// Third Party
import styled from 'styled-components'

// Helpers
import { getLanguage } from 'services/language'

export const pageQuery = graphql`
  query($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      uri
      pageContact {
        contactbanner {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality:100)
                meta: fluid(maxWidth: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
          title
        }

        contactintro {
          title
        }

        data {
          title
        }

      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

const TitleContainer = styled.div`
  @media screen and (max-width: 450px) {
    br {
      display: none;
    }
  }
`

const PageTemplate = ({
  data: {
    page: { uri, seo, pageContact },
  },
}) => {
  const language = getLanguage()
  return (
    <Layout>
      {seo && (
        <SEO
          seo={seo}
          socialImage={pageContact.contactbanner.image.localFile.childImageSharp.meta.src}
        />
      )}
      <Hero src={pageContact.contactbanner.image} title={pageContact.contactbanner.title} />

      <section className="mb-5">
        <div className="container pb-5">
          <div className="row py-5">
            <TitleContainer className="col-lg-6 pb-lg-0 pb-5">
              <ParseContent content={pageContact.contactintro.title} />
            </TitleContainer>
            <div className="col-lg-6">
              <h3 className="mb-4">{pageContact.data.title}</h3>
              <ContactDetails />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 d-none d-lg-block" />
            <div className="col-lg-8 pl-lg-5">
              {language === 'nl_NL' ? (
                <GravityForm id={1} />
              ) : (
                <GravityForm id={3} />
              )}
            </div>
          </div>
        </div>
      </section>
      <KoelMonteurs />
    </Layout>
  )
}

export default PageTemplate
